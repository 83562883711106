import React, { useRef, useEffect, useCallback } from 'react';
import styles from './styles';

const Map = () => {
  const mapElRef = useRef(null);
  const mapRef = useRef(null);

  const initMap = useCallback(() => {
    if (window && window.google) {
      const icon = {
        path:
          'M512,0C229.23,0,0,229.23,0,512s229.23,512,512,512,512-229.23,512-512S794.77,0,512,0ZM793.15,571.57H571.57V793.15H452.43V571.57H230.85V452.43H452.43V230.85H571.57V452.43H793.15Z',
        fillColor: '#fff',
        fillOpacity: 1,
        anchor: new window.google.maps.Point(512, 512),
        scale: 0.05,
      };

      mapRef.current = new window.google.maps.Map(mapElRef.current, {
        center: { lat: 46.8045644, lng: -71.2260257 },
        zoom: 17,
        styles,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      });

      return new window.google.maps.Marker({
        position: { lat: 46.8045644, lng: -71.2260257 },
        map: mapRef.current,
        icon,
      });
    }

    return false;
  }, [mapElRef]);

  useEffect(() => {
    let script = document.getElementById('google-map-script-loader');

    if (script) initMap();

    if (!script) {
      script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GCP_API_KEY}`;
      script.defer = true;
      script.async = true;
      script.id = 'google-map-script-loader';

      document.head.appendChild(script);
    }

    script.addEventListener('load', initMap);

    return () => script.removeEventListener('load', initMap);
  }, [initMap]);

  return (
    <div
      style={{ width: '100%', height: '100%', boxSizing: 'initial' }}
      ref={mapElRef}
    />
  );
};

export default Map;
