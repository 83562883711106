/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line no-unused-vars
import React from 'react';
import PropTypes from 'prop-types';
import Map from './Map';

const Layout = ({ children }) => {
  return (
    <>
      <div
        itemScope
        itemType='https://schema.org/HairSalon'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          minHeight: '100vh',
        }}
      >
        <main
          sx={{
            flexGrow: 1,
            flexBasis: `50%`,
            minWidth: 320,
            '@media (min-aspect-ratio: 1/1)': {
              overflow: 'scroll',
              maxHeight: '100vh',
            },
          }}
        >
          {children}
        </main>

        <aside
          sx={{
            flexGrow: 99999,
            flexBasis: 0,
            backgroundColor: '#F3F3F3',
            minWidth: '50%',
            minHeight: '400px',
          }}
        >
          <Map />
        </aside>
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
