/** @jsx jsx */
import { jsx } from 'theme-ui';

const Logo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 779.09 350.85'
    fill='currentColor'
    {...props}
  >
    <path d='M778.32,223H717.13l-.23,33.31s-.42,35.43-43,35.43-45.12-35.43-45.12-35.43V160.8H774.94l-.44-57.48H628.77V42.44H567.86V253.21s3.06,97.64,105.16,97.64C795,350.85,778.32,223,778.32,223ZM617.23,83H603.05V97.73h-8.67V83H580.2V74.6h14.18V60h8.67V74.6h14.18Z' />
    <path d='M234.78,211.31l68.44-.91s1.68,133.08-141.3,137.68C.53,353.23,0,175.84,0,175.84S-4.12,0,159.87,0c128.92-1,140.77,118.6,140.77,118.6l-70.38,1.48c0-17.62-25.09-56.07-71-55.62-87.58,0-85.39,111.65-85.39,111.65s.3,112.65,86.5,109.36c57.29-2.14,71.1-50.27,74.41-74.17' />
    <path d='M430.94,290.4c-45.12,0-44.71-42.61-44.71-42.61V100.38H325.78V257.54c0,6.38,7.46,91.68,89.43,91.68,48,0,66.26-27.73,66.26-27.73l.66,21.49h57.32V100.38H477.74V247.59S476.09,290.4,430.94,290.4Z' />
    <path d='M324.27,335a15.4,15.4,0,1,1-15.39-15.39A15.37,15.37,0,0,1,324.27,335Z' />
    <path d='M584.56,334a15.39,15.39,0,1,1-15.38-15.41A15.41,15.41,0,0,1,584.56,334Z' />
    <path d='M673.42,96.47V93.62a14.07,14.07,0,0,1-10,3.83,14.55,14.55,0,0,1-11.19-5,16.11,16.11,0,0,1-4.31-11.37,16.38,16.38,0,0,1,4.38-11.47,14.37,14.37,0,0,1,11.12-4.94,14.16,14.16,0,0,1,10,3.85v-3h7.32V96.47ZM670.73,74.6a8.81,8.81,0,0,0-6.37-2.6,9.07,9.07,0,1,0,0,18.13,9.12,9.12,0,0,0,9.06-9.1A8.9,8.9,0,0,0,670.73,74.6Z' />
    <path d='M703.52,72.39a6.61,6.61,0,0,0-6.67,1.46A6,6,0,0,0,695,78.2V96.47h-7.35V65.55H695V67a11.47,11.47,0,0,1,7.15-2.36,22.26,22.26,0,0,1,3.13.32v6.21Z' />
    <path d='M723.87,67.2v5.86h-5V84.39a31.59,31.59,0,0,0,.15,4,1.52,1.52,0,0,0,.65.92,2,2,0,0,0,1.25.38,9.26,9.26,0,0,0,2.94-.73l.55,5.71a14.23,14.23,0,0,1-5.78,1.12,8.52,8.52,0,0,1-3.57-.7,4.76,4.76,0,0,1-2.33-1.72,7,7,0,0,1-1-2.89,37.65,37.65,0,0,1-.23-5.22V73.06h-3.37V67.2h3.37V61.69l7.41-4.39v9.9Z' />
    <path d='M353.7,91.26a16.38,16.38,0,1,1-24.59-21.53,15.88,15.88,0,0,1,11.57-4.8,15.41,15.41,0,0,1,7.51,2,20.79,20.79,0,0,1,6.37,5.6l-6.09,3.87c-2.37-2.76-5-4.14-7.79-4.14a8.83,8.83,0,0,0-6.42,2.66,9.14,9.14,0,0,0,0,12.83,8.77,8.77,0,0,0,6.42,2.64c2.78,0,5.41-1.39,7.79-4.2l4.92,3.13Z' />
    <path d='M387.3,93a16.49,16.49,0,1,1,4.78-11.58A16.12,16.12,0,0,1,387.3,93Zm-5.25-18a8.69,8.69,0,0,0-6.37-2.66,9.07,9.07,0,1,0,0,18.13,8.63,8.63,0,0,0,6.37-2.64,8.95,8.95,0,0,0,0-12.83Z' />
    <path d='M404.82,63.36a4.72,4.72,0,0,1-6.57,0,4.52,4.52,0,0,1-1.34-3.3,4.46,4.46,0,0,1,1.34-3.26,4.72,4.72,0,0,1,6.57,0,4.43,4.43,0,0,1,1.31,3.26A4.49,4.49,0,0,1,404.82,63.36Zm-6.91,33.38V65.85h7.33V96.74Z' />
    <path d='M424.1,64a5.65,5.65,0,0,0-1.94-1.2,1,1,0,0,0-.74.34,1.05,1.05,0,0,0-.28.74v4.81h4.66v7.36h-4.66v30.15H413.8V76.07H411V68.71h2.82V63.9a8.45,8.45,0,0,1,8.36-8.43,10.52,10.52,0,0,1,5.37,1.78Z' />
    <path d='M444.37,64a5.38,5.38,0,0,0-1.93-1.2,1,1,0,0,0-.73.34,1.12,1.12,0,0,0-.3.74v4.81h4.67v7.36h-4.67v30.15h-7.34V76.07h-2.78V68.71h2.78V63.9a8.45,8.45,0,0,1,8.37-8.43,10.61,10.61,0,0,1,5.39,1.78Z' />
    <path d='M472,96.74V95.41a11.68,11.68,0,0,1-7.21,2.34,11.51,11.51,0,0,1-9.07-4.22,13.44,13.44,0,0,1-3.53-9.38V65.85h7.33V84.16a5.77,5.77,0,0,0,1.92,4.35,6,6,0,0,0,8.7,0A5.79,5.79,0,0,0,472,84.16V65.85h7.3V96.74Z' />
    <path d='M502.21,72.69a6.69,6.69,0,0,0-2.29-.39,5.92,5.92,0,0,0-4.37,1.84,5.84,5.84,0,0,0-1.85,4.36V96.74h-7.34V65.85h7.34v1.46a11.42,11.42,0,0,1,7.14-2.38,21.38,21.38,0,0,1,3.13.34v6.25Z' />
    <path d='M540.52,85H516.19a9.53,9.53,0,0,0,2.64,3.44,9.15,9.15,0,0,0,5.65,2c2.81,0,5.4-1.39,7.76-4.2l6.14,3.88A20.13,20.13,0,0,1,532,95.7a15.08,15.08,0,0,1-7.53,2,16.43,16.43,0,0,1-11.57-28,16.38,16.38,0,0,1,28,11.6A19,19,0,0,1,540.52,85Zm-23.24-9.11a10.4,10.4,0,0,0-1.09,1.8h16.58a8.8,8.8,0,0,0-2.67-3.41,8.65,8.65,0,0,0-5.62-2,9.13,9.13,0,0,0-5.65,2A9.76,9.76,0,0,0,517.28,75.84Z' />
  </svg>
);

export default Logo;
