/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { Helmet } from 'react-helmet';

import Layout from '../components/layout';
import Logo from '../components/Logo';
import FacebookLogo from '../components/FacebookLogo';

const IndexPage = () => (
  <Layout>
    <Helmet
      htmlAttributes={{
        lang: 'fr',
      }}
      title='Salon CUT'
      meta={[
        {
          property: `og:title`,
          content: 'Salon CUT',
        },
      ]}
    />

    <Box p={[2, 2, 4]} sx={{ textAlign: 'center' }}>
      <header>
        <h1
          itemProp='name'
          sx={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 384 }}
        >
          <Logo />
        </h1>
      </header>

      <section id='horaire'>
        <h2>Horaire du salon</h2>

        <ul
          sx={{
            listStyle: 'none',
            paddingLeft: 0,
          }}
        >
          <li itemProp='openingHours' content='Su close'>
            Dimanche - Fermé
          </li>
          <li itemProp='openingHours' content='Mo close'>
            Lundi - Fermé
          </li>
          <li itemProp='openingHours' content='Tu 10:00-18:00'>
            Mardi - <time dateTime='10:00'>10h</time> à{' '}
            <time dateTime='18:00'>18h</time>
          </li>
          <li itemProp='openingHours' content='We 10:00-18:00'>
            Mercredi - <time dateTime='10:00'>10h</time> à{' '}
            <time dateTime='18:00'>18h</time>
          </li>
          <li itemProp='openingHours' content='Th 10:00-20:30'>
            Jeudi - <time dateTime='10:00'>10h</time> à{' '}
            <time dateTime='20:30'>20h30</time>
          </li>
          <li itemProp='openingHours' content='Fr 10:00-20:30'>
            Vendredi - <time dateTime='10:00'>10h</time> à{' '}
            <time dateTime='20:30'>20h30</time>
          </li>
          <li itemProp='openingHours' content='Sa 10:00-17:00'>
            Samedi - <time dateTime='10:00'>10h</time> à{' '}
            <time dateTime='17:00'>17h</time>
          </li>
        </ul>
      </section>

      <section id='coordonnees'>
        <h2>Adresse</h2>

        <address sx={{ fontStyle: 'inherit' }}>
          <p
            itemProp='address'
            itemScope
            itemType='http://schema.org/PostalAddress'
          >
            <a
              href='https://goo.gl/maps/B4eF4BqPp2rUffmDA'
              sx={{ color: 'currentColor', textDecoration: 'inherit' }}
            >
              <span itemProp='streetAddress'>
                43, Boulevard René-Lévesque Ouest
              </span>
              <br />
              <span itemProp='addressLocality'>Québec</span>,{' '}
              <span itemProp='addressRegion'>QC</span>{' '}
              <span itemProp='postalCode'>G1R 2A3</span>
            </a>
          </p>

          <p>
            <a
              href='tel:+14185231919'
              itemProp='telephone'
              sx={{ color: 'currentColor', textDecoration: 'inherit' }}
            >
              418-523-1919
            </a>
          </p>

          <p>
            <a
              sx={{ color: 'currentColor', textDecoration: 'inherit' }}
              href='//www.facebook.com/saloncut'
            >
              <FacebookLogo /> Suivez-nous
            </a>
          </p>
        </address>
      </section>
    </Box>
  </Layout>
);

export default IndexPage;
