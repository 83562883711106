import React from 'react';

const FacebookLogo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 1024 1024'
    fill='currentColor'
    style={{
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '1.5em',
      margin: '.5em',
    }}
    {...props}
  >
    <path d='M1024,512C1024,229.23,794.77,0,512,0S0,229.23,0,512c0,255.55,187.23,467.37,432,505.78V660H302V512H432V399.2C432,270.88,508.44,200,625.39,200c56,0,114.61,10,114.61,10V336H675.44c-63.6,0-83.44,39.47-83.44,80v96H734L711.3,660H592v357.78C836.77,979.37,1024,767.55,1024,512Z' />
  </svg>
);

export default FacebookLogo;
